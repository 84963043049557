var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"create-or-update-user-modal","centered":"","scrollable":"","size":"xl","header-class":"modal__header","footer-class":"modal__footer","body-class":"modal__body","no-close-on-backdrop":""},on:{"hidden":_vm.onHidden},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){return [_c('h3',{staticClass:"font-weight-bold"},[_vm._v("เพิ่ม/แก้ไขข้อมูลผู้ใช้งาน")]),(_vm.editData && _vm.editData.updatedAt)?_c('span',[_vm._v("อัพเดตล่าสุด: "+_vm._s(_vm.$_formatDateMixin_formatShort(_vm.editData.updatedAt)))]):_vm._e()]}},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"dark","disabled":_vm.isSubmiting},on:{"click":function($event){return cancel()}}},[_vm._v(" ยกเลิก ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":"","label":"submiting..."}}):_c('span',[_vm._v("บันทึก")])],1)]}}]),model:{value:(_vm.$show),callback:function ($$v) {_vm.$show=$$v},expression:"$show"}},[_c('user-information-form',{staticClass:"mb-3",attrs:{"user-data":_vm.editData}}),_c('filter-master',{attrs:{"m-headquarter-id":_vm.editData && _vm.editData.mHeadquarterId
        ? _vm.editData.mHeadquarterId
        : !_vm.authUser.canAccessAllHeadQuarters
        ? _vm.authUser.mHeadquarterId
        : null,"m-division-id":_vm.editData && _vm.editData.mDivisionId
        ? _vm.editData.mDivisionId
        : !_vm.authUser.canAccessAllDivisions
        ? _vm.authUser.mDivisionId
        : null,"m-station-id":_vm.editData && _vm.editData.mStationId
        ? _vm.editData.mStationId
        : !_vm.authUser.canAccessAllStations
        ? _vm.authUser.mStationId
        : null,"disabled-input":_vm.disabledInput || {
        headQuarter:
          !_vm.authUser.canAccessAllHeadQuarters &&
          _vm.authUser.mHeadquarterId !== null,
        division:
          !_vm.authUser.canAccessAllDivisions && _vm.authUser.mDivisionId !== null,
        station:
          !_vm.authUser.canAccessAllStations && _vm.authUser.mStationId !== null,
      },"col-headquarter":"4","col-division":"4","col-station":"4","default-label":"-","hidden-input":{ school: true, schoolClass: true },"invalid":_vm.invalid,"validation":_vm.$v},on:{"update:invalid":function($event){_vm.invalid=$event}},model:{value:(_vm.filterMaster),callback:function ($$v) {_vm.filterMaster=$$v},expression:"filterMaster"}}),_c('teacher-form',{attrs:{"edit-data":_vm.editData,"disabled":_vm.isSubmiting,"disabledInput":{
      isAdmin: _vm.disabledInput.isAdmin || false,
      adminType: _vm.disabledInput.adminType || false,
    },"user-type":_vm.userType,"invalid":_vm.invalid,"validation":_vm.$v},on:{"update:invalid":function($event){_vm.invalid=$event}},model:{value:(_vm.formTeacher),callback:function ($$v) {_vm.formTeacher=$$v},expression:"formTeacher"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }