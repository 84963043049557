<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :per-page="10"
        action-label="แก้ไขข้อมูล"
      >
        <template #cell(actions)="{ item }">
          <!-- <pre>{{ item }}</pre> -->
          <div class="text-center">
            <b-button size="sm" @click="edit(item)">{{
              actionButtonText
            }}</b-button>
          </div>
        </template>

        <template #cell(refresh)="{ item }">
          <div class="text-center">
            <b-button variant="danger" size="sm" @click="remove(item)"
              >ลบ</b-button
            >
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { MRank, User } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "แก้ไข",
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        additionalParams = {},
        numberOfPages = 1;

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "mStationId", this.params.mStationId);
      }

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      if (this.params && this.params.user_type) {
        this.$set(additionalParams, "user_type", this.params.user_type);
      }

      this.isFetching = true;

      try {
        MRank.api().findAll();

        const promise = await User.api().findAll(
          {
            ...additionalParams,
            limit: ctx.perPage,
            offset: (ctx.currentPage - 1) * ctx.perPage,
          },
          { save: false }
        );

        let { data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        data = responseData.map((record) => {
          const recordRank = MRank.find(record.mRankId);

          let retire_year = "-";

          if (record.retire_year) {
            retire_year = record.retire_year;
          }

          let medal = "ระดับต้น";

          if (record.total_time) {
            if (record.total_time >= 200) {
              medal = "ระดับเหรียญทอง";
            } else if (record.total_time >= 150) {
              medal = "ระดับเหรียญเงิน";
            } else if (record.total_time >= 100) {
              medal = "ระดับเหรียญทองแดง";
            }
          }

          return {
            ...record,
            rank: recordRank ? recordRank.m_rank_name : "-",
            email: record.email || "-",
            birth_date: record.birth_date || "-",
            phone_number: record.phone_number
              ? `${record.phone_number.substring(
                  0,
                  3
                )}-${record.phone_number.substring(
                  3,
                  6
                )}-${record.phone_number.substring(6, 10)}`
              : "-",
            retire_year,
            medal,
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      this.numberOfPages = numberOfPages;

      return data;
    },

    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
