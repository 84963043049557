<template>
  <div class="d-flex flex-row flex-wrap">
    <div class="col-lg-3 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="user_countHourLesson"
        >จำนวนสอน (ชั่วโมง)</label
      >
      <b-form-input
        id="user_countHourLesson"
        type="text"
        :disabled="true"
        v-model="totalTime"
      ></b-form-input>
    </div>
    <div class="col-lg-2 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="user_countYearLesson"
        >จำนวนปีที่สอน</label
      >
      <b-form-input
        id="user_countYearLesson"
        type="text"
        :disabled="true"
        v-model="totalYear"
      ></b-form-input>
    </div>
    <div class="col-lg-2 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="ีuser_yearStart"
        >ปีที่เริ่มสอน พ.ศ.</label
      >
      <b-form-input
        id="ีuser_yearStart"
        type="text"
        :disabled="true"
        v-model="startYear"
      ></b-form-input>
    </div>
    <div class="col-lg-3 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="medal"
        >เข็มเชิดชูเกียรติ (ระดับ)</label
      >
      <b-form-input
        id="medal"
        type="text"
        :disabled="true"
        v-model="medal"
      ></b-form-input>
    </div>
    <div class="col-lg-2 col-12 px-2 mb-2">
      <label class="font-weight-bold" for="user_note">หมายเหตุ</label>
      <b-form-input
        id="user_note"
        type="text"
        :disabled="true"
        v-model="note"
      ></b-form-input>
    </div>
  </div>
</template>

<script>
import { map } from "lodash";
export default {
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    userData: {
      immediate: true,
      handler: "setData",
    },
  },

  data() {
    return {
      totalTime: "",
      totalYear: "",
      startYear: "",
      medal: "",
      note: "",
    };
  },

  methods: {
    setData(user) {
      let { total_time = null, teacher_dares = [], medal = null } = user;

      total_time = total_time ? Math.floor(total_time) : 0;

      medal = medal || "ระดับต้น";
      console.log(total_time);
      if (total_time >= 200) {
        medal = "ระดับทอง";
      } else if (total_time >= 150) {
        medal = "ระดับเงิน";
      } else if (total_time >= 100) {
        medal = "ระดับทองแดง";
      }

      this.totalTime = total_time;

      this.medal = medal;

      if (teacher_dares.length > 0) {
        this.note = `${teacher_dares.length} ห้อง`;
        const fiscal_year = map(teacher_dares, "fiscal_year");
        if (fiscal_year) {
          const uniqueYears = [...new Set(fiscal_year)].sort();
          this.totalYear = uniqueYears.length;
          this.startYear = uniqueYears[0];
        }
      }
    },
  },
};
</script>
