<template>
  <b-modal
    id="create-or-update-user-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไขข้อมูลผู้ใช้งาน</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <user-information-form
      class="mb-3"
      :user-data="editData"
    ></user-information-form>

    <filter-master
      v-model="filterMaster"
      :m-headquarter-id="
        editData && editData.mHeadquarterId
          ? editData.mHeadquarterId
          : !authUser.canAccessAllHeadQuarters
          ? authUser.mHeadquarterId
          : null
      "
      :m-division-id="
        editData && editData.mDivisionId
          ? editData.mDivisionId
          : !authUser.canAccessAllDivisions
          ? authUser.mDivisionId
          : null
      "
      :m-station-id="
        editData && editData.mStationId
          ? editData.mStationId
          : !authUser.canAccessAllStations
          ? authUser.mStationId
          : null
      "
      :disabled-input="
        disabledInput || {
          headQuarter:
            !authUser.canAccessAllHeadQuarters &&
            authUser.mHeadquarterId !== null,
          division:
            !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
          station:
            !authUser.canAccessAllStations && authUser.mStationId !== null,
        }
      "
      col-headquarter="4"
      col-division="4"
      col-station="4"
      default-label="-"
      :hidden-input="{ school: true, schoolClass: true }"
      :invalid.sync="invalid"
      :validation="$v"
    >
    </filter-master>

    <teacher-form
      v-model="formTeacher"
      :edit-data="editData"
      :disabled="isSubmiting"
      :disabledInput="{
        isAdmin: disabledInput.isAdmin || false,
        adminType: disabledInput.adminType || false,
      }"
      :user-type="userType"
      :invalid.sync="invalid"
      :validation="$v"
    ></teacher-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click.prevent="save">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  // email,
  required,
  requiredIf,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import TeacherForm from "../form/Teacher";
import FilterMaster from "../form/FilterMaster";
import UserInformationForm from "../form/UserInformation";
import { Auth, User } from "../../models";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {
    TeacherForm,
    FilterMaster,
    UserInformationForm,
  },

  props: {
    show: Boolean,
    editData: {
      type: Object,
      default: () => ({}),
    },
    userType: {
      type: String,
      default: "user",
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      formTeacher: {},
      filterMaster: {},
    };
  },

  validations: {
    filterMaster: {
      mHeadquarterId: { required },
      mDivisionId: { required },
      mStationId: { required },
    },

    formTeacher: {
      // mHeadquarterId: { required },
      // mDivisionId: { required },
      // mStationId: { required },
      mRankId: { required },
      first_name: { required },
      last_name: { required },
      birth_date: { required },
      phone_number: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(12),
      },
      id_card: {
        // required,
        valid: (v) => {
          return v && v.length
            ? validatorMixin.methods.$_validatorMixin_chkDigitPid(v)
            : true;
        },
      },
      user_status: { required },
      admin_type: {
        required: requiredIf(
          (form) =>
            form.user_type === "ADMIN" || form.user_type === "SUPER_ADMIN"
        ),
      },
      // email: { required, email },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async save() {
      this.$v.$touch();

      this.invalid = false;

      if (this.$v.$invalid) {
        this.invalid = true;

        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      const { mHeadquarterId, mDivisionId, mStationId } = this.filterMaster;

      const formData = {
        ...this.formTeacher,
        id_card: this.formTeacher.id_card.replace(/-/g, ""),
        phone_number: this.formTeacher.phone_number.replace(/-/g, ""),
        user_name: this.formTeacher.first_name,
        user_approve: "approve",
        user_type: this.formTeacher.user_type || this.userType,
        mHeadquarterId,
        mDivisionId,
        mStationId,
      };

      let promise;

      this.isSubmiting = true;

      try {
        if (this.editData && this.editData.id) {
          promise = await User.api().update(this.editData.id, formData);
        } else {
          promise = await User.api().create(formData);
        }

        const { data } = promise.response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลผู้ใช้งานเรียบร้อยแล้ว`
          );

          this.$bvModal.hide("create-or-update-user-modal");

          this.$emit("create:success");
        } else {
          this.$toast.error(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลผู้ใช้งานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.invalid = false;

      this.formTeacher = {};

      this.$emit("update:editData", {});
    },
  },
};
</script>
